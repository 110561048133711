<template>
  <b-card>
    <b-card-title class="font-title mb-1">
      <feather-icon
          class="mr-1"
          icon="TriangleIcon"
          size="18"
      />
      {{title}}
    </b-card-title>
    <b-row>
      <b-col cols="12">
        <div class="d-flex position-relative w-100">
          <div :id="'real_time_air_quality_chart_1_'+nanoid" style="width: 22%;height: calc((100vh - 16rem) / 3 - 8rem);"></div>
          <div :id="'real_time_air_quality_chart_2_'+nanoid"
               style="width: 20%;height: calc((100vh - 16rem) / 3 - 8rem);margin-top: -2rem"></div>
          <div :id="'real_time_air_quality_chart_4_'+nanoid"
               style="width: 22%;height: calc((100vh - 16rem) / 3 - 8rem);margin-top: 1rem"></div>
          <div :id="'real_time_air_quality_chart_3_'+nanoid"
               style="width: 15%;height: calc((100vh - 16rem) / 3 - 8rem);margin-top: -1.5rem"></div>
          <div :id="'real_time_air_quality_chart_5_'+nanoid" style="width: 18%;height: calc((100vh - 16rem) / 3 - 8rem);"></div>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="text-center mt-50">
          <span class="mr-3">室外温度 <span class="text-success font-digit h4">{{ params.TEMP }}</span> °C</span>
          <span class="mr-3">室外湿度 <span class="text-success font-digit h4">{{ params.HUMI }}</span> %</span>
          <span>室外PM2.5 <span class="text-success font-digit h4">{{ params.PM25 }}</span> μg/m3</span>
        </div>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {RingProgress} from "@antv/g2plot";
import {onMounted, onUnmounted, reactive, toRefs} from "@vue/composition-api";
import {getRealTimeParams} from "@/api/hjj2";
import {routerParams} from "@/libs/utils/routerParams";
import {new_nanoid} from "@/libs/utils/nanoId";

export default {
  name: "RealTimeAirQuality",
  props:['title'],
  setup() {
    const project_id = routerParams('project_id')
    const nanoid = new_nanoid()
    const event = reactive({
      chart_1: null,
      chart_2: null,
      chart_3: null,
      chart_4: null,
      chart_5: null,
      params: {
        TEMP: 0,
        HUMI: 0,
        PM25: 0,
        realCO2: 0,
        realHumidity: 0,
        realPM25: 0,
        realTemperature: 0,
        realVOC: 0,
      }
    })

    const chart_init_all = () => {
      chart_init_1()
      chart_init_2()
      chart_init_3()
      chart_init_4()
      chart_init_5()
    }

    const chart_init_1 = () => {
      const value = event.params.realTemperature
      if (event.chart_1) {
        event.chart_1.destroy()
        event.chart_1 = null
      }
      const ringProgress = new RingProgress("real_time_air_quality_chart_1_"+nanoid, {
        autoFit: true,
        animation: false,
        percent: value / 100,
        color: ["#4169E1", "#161D31"],
        statistic: {
          title: {
            style: {
              color: "#4169E1",
              fontSize: "1.5rem",
              lineHeight: "2rem",

            },
            formatter: () => "温度",
          },
          content: {
            customHtml: (container, view, datum, data) => {
              const {width} = container.getBoundingClientRect();
              const text = value + " °C"
              const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:#82868b;line-height:20px;`;
              return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
            },
          },
        },
      });
      ringProgress.render();
      event.chart_1 = ringProgress

    }
    const chart_init_2 = () => {
      const value = event.params.realHumidity
      if (event.chart_2) {
        event.chart_2.destroy()
        event.chart_2 = null
      }
      const ringProgress = new RingProgress("real_time_air_quality_chart_2_"+nanoid, {
        autoFit: true,
        animation: false,
        percent: value / 100,
        color: ["#00FF00", "#161D31"],
        statistic: {
          title: {
            style: {
              color: "#00FF00",
              fontSize: "1.5rem",
              lineHeight: "2rem",

            },
            formatter: () => "湿度",
          },
          content: {
            customHtml: (container, view, datum, data) => {
              const {width} = container.getBoundingClientRect();
              const text = value + " %"
              const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:#82868b;line-height:20px;`;
              return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
            },
          },
        },
      });

      ringProgress.render();
      event.chart_2 = ringProgress
    }
    const chart_init_3 = () => {
      const value = event.params.realCO2
      if (event.chart_3) {
        event.chart_3.destroy()
        event.chart_3 = null
      }
      const ringProgress = new RingProgress("real_time_air_quality_chart_3_"+nanoid, {
        autoFit: true,
        animation: false,
        percent: value / 1000,
        color: ["#FF0000", "#161D31"],
        statistic: {
          title: {
            style: {
              color: "#FF0000",
              fontSize: "1.5rem",
              lineHeight: "2rem",

            },
            formatter: () => "CO2",
          },
          content: {
            customHtml: (container, view, datum, data) => {
              const {width} = container.getBoundingClientRect();
              const text = value + " mg/m3"
              const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:#82868b;line-height:20px;`;
              return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
            },
          },
        },
      });

      ringProgress.render();
      event.chart_3 = ringProgress
    }
    const chart_init_4 = () => {
      const value = event.params.realPM25
      if (event.chart_4) {
        event.chart_4.destroy()
        event.chart_4 = null
      }
      const ringProgress = new RingProgress("real_time_air_quality_chart_4_"+nanoid, {
        autoFit: true,
        animation: false,
        percent: value / 100,
        color: ["#FFD700", "#161D31"],
        statistic: {
          title: {
            style: {
              color: "#FFD700",
              fontSize: "1.5rem",
              lineHeight: "2rem",

            },
            formatter: () => "PM2.5",
          },
          content: {
            customHtml: (container, view, datum, data) => {
              const {width} = container.getBoundingClientRect();
              const text = value + " ppm"
              const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:#82868b;line-height:20px;`;
              return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
            },
          },
        },
      });

      ringProgress.render();
      event.chart_4 = ringProgress
    }
    const chart_init_5 = () => {
      const value = event.params.realVOC
      if (event.chart_5) {
        event.chart_5.destroy()
        event.chart_5 = null
      }
      const ringProgress = new RingProgress("real_time_air_quality_chart_5_"+nanoid, {
        autoFit: true,
        animation: false,
        percent: value / 1000,
        color: ["#90EE90", "#161D31"],
        statistic: {
          title: {
            style: {
              color: "#90EE90",
              fontSize: "1.5rem",
              lineHeight: "2rem",

            },
            formatter: () => "VOC",
          },
          content: {
            customHtml: (container, view, datum, data) => {
              const {width} = container.getBoundingClientRect();
              const text = value + " ppb"
              const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:#82868b;line-height:20px;`;
              return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
            },
          },
        },
      });

      ringProgress.render();
      event.chart_5 = ringProgress
    }

    const fetchRealTimeParams = () => {
      getRealTimeParams({project_id: project_id}).then(res => {
        let params = {}
        res.data.map(item => {
          let param_name = item.meter_code
              .replace('06750343', '')
              .replace('B1771C58B5730A3D', '')
              .replace('room1','')
          params[param_name] = item.value
        })
        console.log('real-time-params', params)
        event.params = params
        setTimeout(() => {
          chart_init_all()
        }, 500)
      })
    }

    onMounted(() => {
      // chart_init_all()
      fetchRealTimeParams()
      setInterval(() => {
        fetchRealTimeParams()
      }, 1000 * 5)
    })
    onUnmounted(()=>{
      event.chart_1.destroy()
      event.chart_1 = null
      event.chart_2.destroy()
      event.chart_2 = null
      event.chart_3.destroy()
      event.chart_3 = null
      event.chart_4.destroy()
      event.chart_4 = null
      event.chart_5.destroy()
      event.chart_5 = null
    })

    return {
      ...toRefs(event),
      nanoid
    }

  }
}
</script>

<style scoped>

</style>